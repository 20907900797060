import React from 'react'

import volunteerRevive from '../images/volunteer-revive.svg'
import volunteerEDC from '../images/volunteer-edc.svg'
import volunteerMeld from '../images/volunteer-meld.svg'
import volunteerIMS from '../images/volunteer-ims.png'

import volunteerUFG from '../images/volunteer-ufg.svg'
import volunteerPolk from '../images/volunteer-polk.png'

const SponsorsSection = () => (
  <React.Fragment>
    <section className="section is-paddingless sponsors-primary">
      <div className="container is-fluid">
        <div className="columns">
          <div className="column is-10 center-block">
            <h3 className="has-text-centered">Gold Sponsors</h3>
          </div>
        </div>
      </div>
      <div className="container is-fluid">
        <div className="columns">
          <div className="column is-12 center-block">
            <div className="sponsors-grid">
              <div className="sponsor">
                 <img src={volunteerRevive} alt="sponsor Revive Design Studios logo" />
                  <div className="sponsor-content">
                    <h5>Revive Design Studios</h5>
                    <p>Revive is providing user-centered web design, development, and hosting services.</p><p><a href="https://revivedesignstudios.com/" target="_blank" rel="noreferrer">Visit Website</a></p>
                  </div>
              </div>
              <div className="sponsor">
                  <img src={volunteerEDC} alt="sponsor EDC logo" />
                  <div className="sponsor-content">
                    <h5>EDC</h5>
                    <p>EDC has connected our cause with community leaders while providing organization direction.</p><p><a href="http://www.edcinc.org/" target="_blank" rel="noreferrer">Visit Website</a></p>
                  </div>
              </div>
              <div className="sponsor">
                 <img src={volunteerMeld} alt="sponsor Meld logo" />
                  <div className="sponsor-content">
                    <h5>Meld.</h5>
                    <p>Meld has consulted on social media marketing strategies to propel our message.</p><p><a href="https://meld.marketing/" target="_blank" rel="noreferrer">Visit Website</a></p>
                  </div>
              </div>
              <div className="sponsor">
                 <img src={volunteerIMS} alt="sponsor Iowa Medical Society logo"/>
                  <div className="sponsor-content">
                    <h5>Iowa Medical Society</h5>
                    <p>Iowa Medical Society is provided funding and marketing to the state of Iowa.</p><p><a href="https://www.iowamedical.org/" target="_blank" rel="noreferrer">Visit Website</a></p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section is-paddingless sponsors-secondary">
      <div className="container is-fluid">
        <div className="columns">
          <div className="column is-10 center-block">
            <h4 className="has-text-centered">Silver Sponsors</h4>
          </div>
        </div>
      </div>
      <div className="container is-fluid">
        <div className="columns">
          <div className="column is-12 center-block">
            <div className="sponsors-grid">
              <div className="sponsor">
                <p><a href="/contact-us">Contact us</a> about sponsorshop opportunities</p>
                 {/*<img src={volunteerDubuque} />
                  <div className="sponsor-content">
                    <h5>Dubuque County Health Dept.</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua elit at imperdiet accumsan.</p><p><a href="https://www.dubuquecounty.org/">Visit Website</a></p>
                  </div>*/}
              </div>
            </div>
            {/*<div className="sponsors-grid">
                 <div className="sponsor">
                    <img src={volunteerDubuque} />
                     <div className="sponsor-content">
                       <h5>Dubuque County Health Dept.</h5>
                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua elit at imperdiet accumsan.</p><p><a href="#">Visit Website</a></p>
                     </div>
                 </div>
               </div>
            */}
          </div>
        </div>
      </div>
      <div className="container is-fluid">
        <div className="columns">
          <div className="column is-10 center-block sponsor-border-top">
            <h4 className="has-text-centered">Bronze Sponsors</h4>
          </div>
        </div>
      </div>
      <div className="container is-fluid">
        <div className="columns">
          <div className="column is-12 center-block">
            <div className="sponsors-grid">
              <div className="sponsor">
                 <img src={volunteerUFG} alt="sponsor UFG Insurance logo"/>
                  <div className="sponsor-content">
                    <h5>UFG Insurance</h5>
                    <p>UFG is providing funding to help develop the Business Directory.</p><p><a href="https://www.ufginsurance.com/" target="_blank" rel="noreferrer">Visit Website</a></p>
                  </div>
              </div>
              <div className="sponsor">
                 <img src={volunteerPolk} alt="sponsor Polk County Medical Society logo"/>
                  <div className="sponsor-content">
                    <h5>Polk County Medical Society</h5>
                    <p>PCMS is providing marketing to the businesses of Polk County.</p><p><a href="https://pcms.org/" target="_blank" rel="noreferrer">Visit Website</a></p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
)

export default SponsorsSection
