import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'
import PageHelmet from '../components/pageHelmet'
import SponsorsSection from '../components/sponsorsSection'

import forBizHeroImg from '../images/hero-women-flower-shopping.jpg'
import forBizSvg from '../images/icon-handshake.svg'

const SponsorsPage = () => (
  <Layout>
    <PageHelmet
      title="Our Sponsors"
      description="If you'd like to support our cause, please consider a donation to helps offset the cost of our operations."
      image={forBizHeroImg} />
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'Our'}
      accentedTitle={'Sponsors'}
      subtitle={'Businesses, Organizations, and Individuals Supporting Our Cause'}
    />

    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-8 center-block">
            <br />
            <div class="columns has-text-centered">
              <div class="column">
                <p>
                  ‘Mask of Wellness’ is partnering with companies, governments, nonprofits and other organizations to promote ‘Covid-Conscious’ practices on a nationwide scale. Our partners contribute more than money. Their ideas, volunteer power, in-kind support and more are helping improve social, physical, and economic wellness in communities everywhere.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <SponsorsSection />

    <section className="mow-border-top">
      <div className="columns is-marginless v-align-center">
        <div className="column square-bg-sponsors">
        </div>
        <div className="column home-half-text-left">
            <h2>Become a Sponsor</h2>
            <p>By becoming a sponsor, you will be reinvigorating your local and national economy as well as increasing your exposure as an organization that cares about community wellness. As we broaden our message nationally, our grassroots team is looking for support from socially responsible corporations.</p>
            <p>Your support will be used primarily for our continued outreach efforts, enhancements to our searchable database of ‘Covid-Concscious’ businesses, and further development of educational and promotional materials. Contact us for different sponsorship opportunities and the benefits you will receive as a sponsor.</p>
            <Link to="/contact-us/">
              <button className="button">CONTACT US</button>
            </Link>
        </div>
    </div>
    </section>


  </Layout>
)

export default SponsorsPage
